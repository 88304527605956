.contact-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Goudy Old Style', serif; /* Set font for the entire contact page */
    margin-top: 5%;
  }
  
  .header-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header-image {
    width: 300px; /* Adjust the size as needed */
    height: auto;
    margin-bottom: 10px;
  }
  
  .header-text {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }
  
  .contact-info {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .contact-info a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: none; /* Remove border */
    border-bottom: 1px solid #ccc; /* Add underline */
    font-family: 'Goudy Old Style', serif;
    box-sizing: border-box;
  }
  
  .form-group input::placeholder,
  .form-group textarea::placeholder {
    color: #888;
  }
  
  .form-group textarea {
    height: 150px;
    resize: vertical; /* Allows vertical resizing */
  }
  
  .split-form {
    display: flex;
    gap: 20px;
  }
  
  .form-half {
    flex: 1;
  }
  
  button {
    background-color: #232323; /* Set button color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Goudy Old Style', serif;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #1a1a1a; /* Slightly darker color on hover */
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  
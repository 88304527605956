@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');

.fashion-shoots-container {
  font-family: 'Nanum Myeongjo', Arial, sans-serif; /* Apply Nanum Myeongjo font */
  color: #333;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  margin-top: 20px; /* Push down content slightly */
}

.hero-section {
  text-align: center;
  margin-bottom: 40px;
  max-width: 100%;
  padding: 0 20px; /* Padding for edge spacing */

  .hero-title {
    font-size: 2.5rem;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .hero-description {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto; /* Centered description */
  }
}

.gallery-section {
  width: 100%;
  max-width: 1200px; /* Maximum width for the gallery */
  padding: 0 20px; /* Padding for edge spacing */
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive columns */
    gap: 20px;
    margin-top: 20px;
  }

  .gallery-item {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    img {
      width: 100%;
      height: 300px; /* Set image height */
      object-fit: cover;
    }
  }
}

// Responsive design adjustments
@media (max-width: 768px) {
  .fashion-shoots-container {
    margin-top: 100px; /* Reduce margin-top for mobile */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .hero-title {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  .hero-description {
    font-size: 1rem;
    max-width: 100%; /* Ensure full width for description */
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust columns */
    gap: 15px;
  }

  .gallery-item {
    img {
      height: 200px; /* Adjust image height for mobile */
    }
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    .gallery-item {
      img {
        height: 150px; /* Further adjust image height for very small screens */
      }
    }
  }
}

// src/styles/AdFilms.scss
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');

.ad-films-container {
  font-family: 'Nanum Myeongjo', Arial, sans-serif; /* Use Nanum Myeongjo font */
  color: #333;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Ensure full viewport height */
  margin-top: 20px; /* Push the page content down a little */
}

.hero-section {
  text-align: center;
  margin-bottom: 40px;
  max-width: 100%;
  padding: 0 20px; /* Space at edges */
  
  .hero-title {
    font-size: 2.5rem;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .hero-description {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
  }
}

.videos-section {
  width: 100%;
  max-width: 1200px; /* Control maximum width */
  padding: 0 20px; /* Space at edges */
  
  .video-item {
    margin-bottom: 40px;

    .video-title {
      font-size: 1.5rem;
      color: #2c3e50;
      margin-bottom: 10px;
      text-align: center;
    }

    .video-iframe {
      width: 100%;
      height: 500px; /* Aspect ratio 16:9 */
      border: none;
    }
  }
}

// Responsive design adjustments
@media (max-width: 768px) {
  .ad-films-container {
    margin-top: 100px; /* Reduce margin-top for mobile view */
    padding: 10px; /* Adjust padding for mobile */
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 1rem;
    max-width: 100%;
  }

  .video-iframe {
    height: 200px; /* Adjust video height for smaller screens */
  }
}

@media (max-width: 480px) {
  .video-iframe {
    height: 150px; /* Further adjust video height for very small screens */
  }
}

// src/styles/CustomAudioPlayer.scss
.custom-audio-player {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    position: relative;
  
    .time-display {
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
      color: #666;
      margin-bottom: 5px;
    }
  
    .progress-bar {
      appearance: none;
      width: 100%;
      height: 5px;
      background: #791e1e;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      background-color: #ddd; /* Default track color */
      position: relative;
  
      &::-webkit-slider-runnable-track {
        background: #8f0000;
        border-radius: 5px;
      }
  
      &::-webkit-slider-thumb {
        appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #333;
        margin-top: -4px; /* Adjusts for thumb alignment */
      }
  
      &::-moz-range-track {
        background: #ddd;
        border-radius: 5px;
      }
  
      &::-moz-range-thumb {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #333;
      }
  
      /* Use a pseudo-element to create the fill color */
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%; /* This will be updated by JavaScript */
        background-color: #b72b2b; /* Fill color */
        border-radius: 5px;
        z-index: 1;
        pointer-events: none;
      }
  
      &::-webkit-slider-runnable-track {
        background-color: transparent;
      }
    }
  
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
   
      margin-top: 1px;
  
      .play-pause-button, .mute-button {
        background: transparent;
        border: none;
        color: #000000;
        font-size: 1rem;
        cursor: pointer;
        outline: none;
        &:hover {
          color: #a07f70;
        }
      }
    }
  }
  
/* Checkout Page */
.checkout-page1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 100px; /* Adds space from the top */
}

.checkout-form {
  width: 100%;
  max-width: 600px;
  background: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout-form h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.checkout-form label {
  display: block;
  margin-bottom: 15px;
}

.checkout-form input,
.checkout-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.checkout-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.checkout-form button:hover {
  background-color: #0056b3;
}

.checkout-cart-summary {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.checkout-cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkout-cart-item-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.checkout-cart-item-details {
  flex-grow: 1;
}

.checkout-cart-total {
  margin-top: 10px;
  text-align: right;
  font-size: 1.2em;
  font-weight: bold;
}

.checkout-paypal-button-container {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .checkout-page {
    padding: 15px;
    margin-top: 100px;
  }

  .checkout-form {
    padding: 15px;
  }

  .checkout-form button {
    padding: 12px;
  }

  .checkout-cart-summary {
    margin-bottom: 15px;
  }

  .checkout-paypal-button-container {
    max-width: 100%;
  }
}

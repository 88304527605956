.footer {
  padding: 20px;
  background-color: #edeadf;
  font-family: 'Lora', serif; /* Update font family */
  font-size: 1rem; /* Base font size, adjust as needed */
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  margin-bottom: 20px;
}

.logo {
  max-width: 300px;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.2rem; /* Increase font size */
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 15px 30px; /* Adjust spacing between columns and rows */
}

.links-grid ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.links-grid li {
  margin-bottom: 15px;
}

.footer-links a {
  color: black;
  text-decoration: none;
  font-size: 1.1rem; /* Increase link font size */
}

.footer-links a:hover {
  color: #333;
}

.footer-divider {
  height: 100px;
  width: 1px;
  background-color: #ccc;
  margin: 0 20px;
}

.footer-social {
  padding-bottom: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
  font-size: 1.2rem; /* Increase font size */
}

.footer-social h4 {
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.social-icons i {
  font-size: 28px; /* Increase icon size */
  color: black;
  transition: color 0.3s;
}

.social-icons a:hover i {
  color: #333;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 1.2rem; /* Increase font size */
}

.footer-bottom hr {
  margin: 10px 0;
}

.footer-bottom a {
  color: black;
  text-decoration: none;
  font-size: 1.1rem; /* Increase font size */
}

.footer-bottom a:hover {
  color: #333;
}

@media (max-width: 768px) {
  .footer-links {
    text-align: center;
  }

  .links-grid {
    grid-template-columns: 1fr;
  }
}

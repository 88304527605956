/* src/styles/ThankYou.scss */
.thank-you-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  .thank-you-page h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .thank-you-page p {
    font-size: 1rem;
    color: #555;
    text-align: center;
    max-width: 600px;
  }
  
  @media (max-width: 768px) {
    .thank-you-page h2 {
      font-size: 1.5rem;
    }
  
    .thank-you-page p {
      font-size: 0.9rem;
    }
  }
  